@import '../scss/config';

.navbar {

    .nav-link:hover,
    .nav-link:focus {
        color: $dark;
    }

}

.navbar.active {
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
    position: fixed;
    top: 54;
    right: 0;
    left: 0;
    z-index: 1030;
    background-color: rgba(0, 0, 0, 0.4);

    .nav-link {
        color: $light;
    }

    .nav-link:hover,
    .nav-link:focus {
        color: $secondary;
    }

    .navbar-brand {
        color: $light;
    }
}

/* change navbar background on collapse  */
@media (max-width: 1200px) {
    .navbar.toggle {
        box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.1);
        position: fixed;
        top: 54;
        right: 0;
        left: 0;
        z-index: 1030;
        background-color: $light;

/*         .nav-link {
            color: $dark;
        }

        .nav-link:hover,
        .nav-link:focus {
            color: $dark;
            text-decoration: none;
        }

        .navbar-brand {
            color: $dark;
        } */

    }

    .navbar-toggler {
        background-color: rgb(255, 255, 255, 0.8);
    }
    
}