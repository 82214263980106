@import '../scss/config';

.timeline-item-title {
    .active {
        color: $dark !important;
    }
    overflow: visible !important;
}

.card-sub-title {
    color: $dark !important;
}


.vertical-item-row {
    div {
        &:nth-child(3n):after {
            background-color: $primary;

        }
        &:nth-child(3n):before {
            background-color: $primary;

        }
        .timeline-vertical-circle {
            div {
                background: $primary !important;
            }
        }
    }
}