.wave
{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}

.waveFooter
{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
}