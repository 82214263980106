.carousel-caption {
    padding-bottom: 0;
    padding-top: 0;
    bottom: 0;
    top: 50%;
    transform: translateY(-50%);
}

.carousel-caption>h1 {
    font-size: 3.5rem; 
}

.carousel-indicators button {
    background-color: rgba(255, 255, 255, 0.8) !important;
}

.carousel-indicators .active {
    background-color: rgb(255, 255, 255, ) !important;
}

.carousel-control-prev>.carousel-control-prev-icon,
.carousel-control-next>.carousel-control-next-icon {
    background-color: rgba(0, 0, 0, 0.8);
}

.carousel-inner>.carousel-item:before {
    content:"";
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    background:rgba(0,0,0,0.4);
}

.carousel-inner>.carousel-item>img,
.carousel-inner>.carousel-item>a>img {
    display: block;
    max-width: 100%;
    height: 1024px !important;

}

@media screen and (max-width: 575.98px) {

    .carousel-inner>.carousel-item>img,
    .carousel-inner>.carousel-item>a>img {
        display: block;
        max-width: 100%;
        height: 400px !important;
    }
    .carousel-caption>h1 {
        font-size: 1.5rem; 
    }
}

@media screen and (min-width: 576px) and (max-width: 767.98) {

    .carousel-inner>.carousel-item>img,
    .carousel-inner>.carousel-item>a>img {
        display: block;
        max-width: 100%;
        height: 480px !important;
    }
    .carousel-caption>h1 {
        font-size: 1.75rem; 
    }
    
}

@media screen and (min-width: 768px) and (max-width: 991.98px) {

    .carousel-inner>.carousel-item>img,
    .carousel-inner>.carousel-item>a>img {
        display: block;
        max-width: 100%;
        height: 540px !important;
    }
    .carousel-caption>h1 {
        font-size: 2rem; 
    }
}

@media screen and (min-width: 992px) and (max-width: 1199.98px) {

    .carousel-inner>.carousel-item>img,
    .carousel-inner>.carousel-item>a>img {
        display: block;
        max-width: 100%;
        height: 600px !important;
    }
    .carousel-caption>h1 {
        font-size: 2.25rem; 
    }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {

    .carousel-inner>.carousel-item>img,
    .carousel-inner>.carousel-item>a>img {
        display: block;
        max-width: 100%;
        height: 720px !important;
    }
    .carousel-caption>h1 {
        font-size: 2.5rem; 
    }
}
@media screen and (min-width: 1200px) {

    .carousel-indicators button {
        background-color: rgba(0, 0, 0, 0.8) !important;
    }

    .carousel-indicators .active {
        background-color: rgb(0, 0, 0) !important;
    }
}