@import '../scss/config';

.floatingButton {

    li {
        button {
            background-color: $dark;
        }
    }
}

.icon-support {
    background-image: url(../assets/images/support.svg);;
    background-size: cover;
    display: inline-block;
    height: 24px;
    width: 24px;
}