.card-img-overlay {
  cursor: pointer;
  opacity: 1;
  transition: .5s ease;

  &:hover {
    transform: scale(1.0);
    opacity: 0.6;
    background: rgba(0, 0, 0, 0.5);
  }

  &:active {
    transform: scale(1.0);
    opacity: 0.6;
    background: rgba(0, 0, 0, 0.5);
  }
}