// overwrite theme variables

$primary: #001C41;
$secondary: #18468C;
$dark: #6CB5E7;
$light: rgb(247, 247, 247);
$success: #66ab57;
$info: #9ab6da;
$warning: #e49b1f;
$danger: #f44336;
